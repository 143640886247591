/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import DataHelper from '../helpers/dataHelper';
import config from '../config';
import JobsList from '../components/JobsList';
import FranchiseJobsList from '../components/franchise/FranchiseJobsList';

// @ts-ignore
const JobList = (props) => {
  const { org } = props;
  return (
    <>
      {org.orgType !== 'franchisor' && <JobsList {...props} />}
      {org.orgType === 'franchisor' && <FranchiseJobsList {...props} />}
    </>
  );
};

// @ts-ignore
export async function getServerSideProps(ctx) {
  const props: { [key: string]: any } = {};

  props.config = config;
  props.orgUrl = ctx.query.orgId;
  props.referer = ctx.req.headers.referer || null;

  // If the URL contains an `int` query parameter, it means the user has arrived from an internal link.
  const internalJobsPortalKey = ctx.query.int || null;

  // fetch the host of the request (the page url)
  let host = ctx.req.headers.host.toLowerCase();

  // strip out the base host value
  host = host.replace(props.config.baseHost, '');

  // for short links we need to strip off any query-string params that may have been added
  // before we evaluate if this is really a short code
  // some added extra qs params; need to remove those
  // example: https://nhsmanagement.chattr.jobs/xfqyptXe&sa=D&ust=1658962860000000&usg=AOvVaw1NUDwY1D10ngjmkSnCS0ty
  let qsForEval = props.orgUrl;
  if (qsForEval.substring(0, 1) === 'x') {
    const qsSplit = qsForEval.split('&');
    if (qsSplit.length > 1) {
      // Not sure how to destructure this correctly and if I did it probably wouldn't be as readable -CB
      // eslint-disable-next-line prefer-destructuring
      qsForEval = qsSplit[0];
    }
  }

  // if we still have leftovers, then we must have a subdomain
  // if the orgUrl (ie, "/whatever") is 7 or 8 characters and starts with a "x" then this is a shortened url
  // we'll handle 8 chars as well; the 8th would be an appended value for "channel" either email or SMS
  // so handle accordingly
  if (
    host.length > 0 &&
    qsForEval.substring(0, 1) === 'x' &&
    (qsForEval.length === 7 || qsForEval.length === 8)
  ) {
    host = host.replace('.', '');

    let channel = null;
    if (qsForEval.length === 8) {
      const channelCode = qsForEval.substring(7).toLowerCase();

      if (channelCode === 'e') {
        channel = 'email';
      } else if (channelCode === 's') {
        channel = 'sms';
      }

      // parse the channel code off the end of the string
      qsForEval = qsForEval.substring(0, 7);
    }

    // console.log(`SubDomain: ${host} - ${qsForEval} - ${channel}`);

    const data: { [key: string]: any } = await DataHelper.transientRequest(
      `${props.config.apiPath}getRedirect_v2`,
      {
        data: {
          orgUrl: host,
          redirectId: qsForEval,
          referer: props.referer,
        },
      },
    );

    props.orgUrl = qsForEval;

    if (data && data.result && data.result.url) {
      let redirectUrl = data.result.url;

      if (channel) {
        redirectUrl += redirectUrl.includes('?') ? `&channel=${channel}` : `?channel=${channel}`;
      }

      // If the user arrives from an internal link, append the internalJobsPortalKey to the redirect URL.
      if (internalJobsPortalKey) {
        redirectUrl += redirectUrl.includes('?')
          ? `&int=${internalJobsPortalKey}`
          : `?int=${internalJobsPortalKey}`;
      }

      return { redirect: { destination: redirectUrl, permanent: false } };
    }

    return { notFound: true };
  }

  props.source =
    ctx.query.source || ctx.query.utm_source || ctx.query.GA || ctx.query.rx_source || null;
  props.campaign = ctx.query.campaign || ctx.query.utm_campaign || ctx.query.rx_paid || null;
  props.qsState = ctx.query.state || '';
  props.qsCity = ctx.query.city || '';
  props.phone = ctx.query.ph || '';

  // check and handle if for some reason this is passed in as "null"
  props.locationId = ctx.query.locationId || null;
  if (props.locationId === 'null') props.locationId = null;
  props.locationLocked = ctx.query.lock === '1'; // if locationLocked qs param is passed in we only show jobs for that location initially

  props.referrerId = ctx.query.referrerId || null;

  props.isRecruitics = !!ctx.query.rx_source;

  props.brandQueryString = ctx.query.brand === 'all' ? '' : ctx.query.brand || '';
  props.int = ctx.query.int || null;

  const data = await DataHelper.getJobPortalDetails(props.config, {
    data: {
      action: 'org',
      orgUrl: props.orgUrl,
      source: props.source,
      referrerId: props.referrerId || null,
      brandQueryString: props.brandQueryString,
      int: props.int,
    },
  });

  if (!ctx.query.brand) {
    ctx.req.query.brand = 'all';
    props.brandQueryString = 'all';
  }

  // make sure we found something; if not, 404 them
  if (!data || !data.result || !data.result.org) {
    return { notFound: true };
  }

  props.org = data.result.org;
  props.referrer = data.result.referrer
    ? `${data.result.referrer.firstname} ${data.result.referrer.lastname}`
    : null;

  props.query = {};
  props.queryString = '';

  for (const prop in ctx.req.query) {
    if (prop !== 'orgId' && prop !== 'jobId' && !prop.includes('/') && prop !== 'l') {
      props.queryString += `${(props.queryString.length === 0 ? '?' : '&') + prop}=${
        ctx.req.query[prop]
      }`;
      props.query[prop] = ctx.req.query[prop];
    }
  }
  props.brand = data.result.brand;

  props.theme = props.org.theme && props.org.theme.name ? props.org.theme.name : 'classic';
  if (ctx.query.theme) {
    props.theme = ctx.query.theme;
  }

  // Pass data to the page via props
  return { props };
}

export default JobList;
